import axiosHttpClient from '@/helpers/axiosHttpClient.js'
import devConsole from '@/helpers/logging/devConsole.js'
import handleApiObject from '@/helpers/logging/handleApiObject.js'
import { defaultExpiresTime, errorRetryTime, nowTime } from '@/stores/storeDefaults.js'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'
import { useStorage } from '@vueuse/core'

export const useModulesStore = defineStore('modules', () => {
	const modules = useStorage('modules', [], sessionStorage)
	const loading = ref(false)
	const expires = useStorage('modules-expires', null, sessionStorage)

	function isEnabled(moduleName) {
		fetchModules()
		const moduleExists = modules?.value?.find?.(module => module.name === moduleName) ?? false
		return moduleExists?.enabled === true
	}

	const list = computed(() => {
		return modules?.value ?? []
	})

	/**
	 * Fetch modules
	 * @param {boolean} [force]
	 * @returns {Promise} A promise to act upon
	 */
	async function fetchModules(force = false) {
		if (force) { loading.value = false }
		if (loading.value) { return }
		if (new Date().getTime() < expires.value && !force) { return }
		if (!useAuthorizationStore().accessToken()) { return }

		loading.value = true
		await axiosHttpClient
			.get('modules/enabled')
			.then(response => {
				modules.value = response?.data?.data ?? {}
				expires.value = nowTime() + defaultExpiresTime
			})
			.catch(e => {
				devConsole('debug', 'useModulesStore: fetch failed')
				expires.value = nowTime() + errorRetryTime
				handleApiObject(e)
			})
			.finally(() => { loading.value = false })
	}

	/**
	 * Clean up the data
	 */
	function remove() {
		devConsole('debug', 'useModulesStore:remove')
		loading.value = false
		modules.value = []
		expires.value = 0
	}

	return {
		modules,
		loading,
		expires,
		isEnabled,
		list,
		fetch: fetchModules,
		remove
	}
})
