const sensoryRoutes = {
	path: '/sensory',
	name: 'modules.sensory',
	redirect: { name: 'sensory.examinations.index' },
	meta: {
		requiresAuth: true,
		permissions: [ 'sensory' ]
	},
	component: () => import('@/modules/sensory/SensoryModule.vue'),
	children: [
		{
			path: 'requests',
			name: 'sensory.requests',
			redirect: { name: 'sensory.requests.index' },
			children: [
				{
					path: '',
					name: 'sensory.requests.index',
					component: () => import('@/modules/sensory/pages/RequestsIndex.vue')
				},
				{
					path: 'create',
					name: 'sensory.requests.create',
					component: () => import('@/modules/sensory/pages/RequestPage.vue'),
					props: { isNew: true, isEdit: true }
				},
				{
					path: ':requestId(\\d+)',
					name: 'sensory.requests.show',
					component: () => import('@/modules/sensory/pages/RequestPage.vue'),
					props: { isNew: false, isEdit: false }
				},
				{
					path: ':requestId(\\d+)/edit',
					name: 'sensory.requests.edit',
					component: () => import('@/modules/sensory/pages/RequestPage.vue'),
					props: { isNew: false, isEdit: true }
				},
				{
					path: ':requestId(\\d+)/tests/create',
					name: 'sensory.requests.tests.create',
					component: () => import('@/modules/sensory/pages/RequestTest.vue'),
					props: { isNew: true, isEdit: true }
				},
				{
					path: ':requestId(\\d+)/tests/:testId(\\d+)',
					name: 'sensory.requests.tests.show',
					component: () => import('@/modules/sensory/pages/RequestTest.vue'),
					props: { isNew: false, isEdit: false }
				},
				{
					path: ':requestId(\\d+)/tests/:testId(\\d+)/edit',
					name: 'sensory.requests.tests.edit',
					component: () => import('@/modules/sensory/pages/RequestTest.vue'),
					props: { isNew: false, isEdit: true }
				},
				{
					path: ':requestId(\\d+)/tests/:testId(\\d+)/products/create',
					name: 'sensory.requests.tests.products.create',
					component: () => import('@/modules/sensory/pages/RequestTestProduct.vue'),
					props: { isNew: true, isEdit: true }
				},
				{
					path: ':requestId(\\d+)/tests/:testId(\\d+)/products/:productId(\\d+)',
					name: 'sensory.requests.tests.products.show',
					component: () => import('@/modules/sensory/pages/RequestTestProduct.vue'),
					props: { isNew: false, isEdit: false }
				},
				{
					path: ':requestId(\\d+)/tests/:testId(\\d+)/products/:productId(\\d+)/edit',
					name: 'sensory.requests.tests.products.edit',
					component: () => import('@/modules/sensory/pages/RequestTestProduct.vue'),
					props: { isNew: false, isEdit: true }
				}
			]
		},
		{
			name: 'sensory.projects',
			path: 'projects',
			redirect: { name: 'sensory.projects.index' },
			children: [
				{
					path: '',
					name: 'sensory.projects.index',
					component: () => import('@/modules/sensory/pages/ProjectsIndex.vue'),
					meta: { permissions: [ 'sensory.projects', 'sensory.projects.projects' ] }
				},
				{
					path: ':projectId(\\d+)',
					name: 'sensory.projects.show',
					component: () => import('@/modules/sensory/pages/ProjectDetail.vue'),
					meta: { permissions: [ 'sensory.projects.projects.show' ] },
					children: [
						{
							path: 'examinations',
							name: 'sensory.projects.examinations'
						},
						{
							path: 'documents',
							name: 'sensory.projects.documents'
						}
					]
				}
			]
		},
		{
			name: 'sensory.examinations',
			path: 'examinations',
			redirect: { name: 'sensory.examinations.index' },
			children: [
				{
					path: '',
					name: 'sensory.examinations.index',
					component: () => import('@/modules/sensory/pages/ExaminationsIndex.vue')
				},
				{
					path: ':examinationId(\\d+)',
					name: 'sensory.examinations.show',
					component: () => import('@/modules/sensory/pages/Examination.vue')
				}
			]
		}
	]
}
export default sensoryRoutes
