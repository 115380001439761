import axiosHttpClient from '@/helpers/axiosHttpClient.js'
import devConsole from '@/helpers/logging/devConsole.js'
import handleApiObject from '@/helpers/logging/handleApiObject.js'
import { defaultExpiresTime, errorRetryTime, nowTime } from '@/stores/storeDefaults.js'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'
import { useStorage } from '@vueuse/core'

export const usePermissionsStore = defineStore('permissions', () => {
	const permissions = useStorage('permissions', [], sessionStorage)
	const loading = ref(false)
	const expires = useStorage('permissions-expires', null, sessionStorage)

	function has(...permissionNames) {
		fetchPermissions()
		if (Array.isArray(permissionNames)) {
			const checkPermissions = permissionNames.map(permissionName => permissions.value?.includes(permissionName))
			return checkPermissions.every(permission => permission === true)
		} else if (typeof permissionNames === 'string') {
			return permissions.value.includes(permissionNames)
		} else {
			throw new Error('permissionNames needs to be an Array of Strings, or a single String')
		}
	}

	function hasAny(...permissionNames) {
		fetchPermissions()
		const checkPermissions = permissionNames.map(permissionName => permissions.value.includes(permissionName))
		return checkPermissions.includes(true)
	}

	const list = computed(() => {
		fetchPermissions()
		return permissions.value.map(permission => permission)
	})

	/**
	 * Fetch permissions
	 * @param {boolean} [force]
	 * @returns {Promise} A promise to act upon
	 */
	async function fetchPermissions(force = false) {
		if (force) { loading.value = false }
		if (loading.value) { return }
		if (new Date().getTime() < expires.value && !force) { return }
		if (!useAuthorizationStore().accessToken()) { return }

		loading.value = true
		await axiosHttpClient
			.get('auth/permissions')
			.then(response => {
				const data = response?.data?.data ?? []
				permissions.value = data.map(permission => permission?.name)
				expires.value = nowTime() + defaultExpiresTime
			})
			.catch(e => {
				expires.value = nowTime() + errorRetryTime
				handleApiObject(e)
			})
			.finally(() => { loading.value = false })
	}

	/**
	 * Clean up the data
	 */
	function remove() {
		devConsole('debug', 'usePermissionsStore:remove')
		loading.value = false
		permissions.value = []
		expires.value = 0
	}

	return {
		permissions,
		loading,
		expires,
		has,
		hasAny,
		list,
		fetch: fetchPermissions,
		remove
	}
})
