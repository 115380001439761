const elearningRoutes = [
	{
		path: '/elearning',
		name: 'module.elearning',
		meta: {
			requiresAuth: true,
			permissions: [ 'elearning' ]
		},
		component: () => import('@/modules/elearning/ElearningModule.vue'),
		children: [
			{
				path: 'my_courses',
				name: 'elearning.my_courses',
				component: () => import('@/modules/elearning/pages/CoursesIndex.vue')
			},
			{
				path: 'my_students',
				name: 'elearning.my_students',
				component: () => import('@/modules/elearning/pages/StudentsIndex.vue')
			},
			{
				path: 'studentgroups',
				name: 'elearning.competenceGroups',
				redirect: { name: 'elearning.competenceGroups.index' },
				children: [
					{
						path: '',
						name: 'elearning.competenceGroups.index',
						component: () => import('@/modules/elearning/pages/CompetenceGroupsIndex.vue'),
						meta: { permissions: [ 'elearning.competence_groups.competence_groups' ] },
					},
					{
						path: ':competenceGroupId(\\d+)',
						name: 'elearning.competenceGroups.show',
						component: () => import('@/modules/elearning/pages/CompetenceGroupShow.vue'),
						meta: { permissions: [ 'elearning.competence_groups.competence_groups.show' ] },
						props: { isNew: false, isEdit: false, },
					},
					{
						path: ':competenceGroupId(\\d+)/edit',
						name: 'elearning.competenceGroups.edit',
						component: () => import('@/modules/elearning/pages/CompetenceGroupPage.vue'),
						meta: { permissions: [ 'elearning.competence_groups.competence_groups.update' ] },
						props: { isNew: false, isEdit: true, },
					},
					{
						path: 'create',
						name: 'elearning.competenceGroups.create',
						component: () => import('@/modules/elearning/pages/CompetenceGroupAdd.vue'),
						meta: { permissions: [ 'elearning.competence_groups.competence_groups.create' ] },
						props: { isNew: true, isEdit: true, },
					}
				]
			},
			{
				path: 'competences',
				name: 'elearning.competences.matrix',
				component: () => import('@/modules/elearning/pages/CompetenceMatrix.vue')
			},
			{
				path: 'courses',
				name: 'elearning.courses',
				redirect: { name: 'elearning.courses.index' },
				children: [
					{
						path: '',
						name: 'elearning.courses.index',
						component: () => import('@/modules/elearning/pages/ELearningManage.vue')
					},
					{
						path: 'create',
						name: 'elearning.courses.create',
						component: () => import('@/modules/elearning/pages/Course.vue'),
						query: { tab: null },
						props: { isNew: true, isEdit: true }
					},
					{
						path: ':courseId(\\d+)',
						name: 'elearning.courses.show',
						component: () => import('@/modules/elearning/pages/Course.vue'),
						query: { tab: null },
						props: { isNew: false, isEdit: false }
					},
					{
						path: ':courseId(\\d+)/edit',
						name: 'elearning.courses.edit',
						component: () => import('@/modules/elearning/pages/Course.vue'),
						query: { tab: null },
						props: { isNew: false, isEdit: true }
					},
					{
						path: ':courseId(\\d+)/slides/create',
						name: 'elearning.courses.slides.create',
						component: () => import('@/modules/elearning/pages/ManageSlide.vue'),
						query: { tab: null, type: 'question' },
						props: { isNew: true, isEdit: true }
					},
					{
						path: ':courseId(\\d+)/slides/:slideId(\\d+)',
						name: 'elearning.courses.slides.show',
						component: () => import('@/modules/elearning/pages/ManageSlide.vue'),
						query: { tab: null },
						props: { isNew: false, isEdit: false }
					},
					{
						path: ':courseId(\\d+)/slides/:slideId(\\d+)/edit',
						name: 'elearning.courses.slides.edit',
						component: () => import('@/modules/elearning/pages/ManageSlide.vue'),
						query: { tab: null },
						props: { isNew: false, isEdit: true }
					}
				]
			}
		]
	},
	{
		path: '/elearning/courses/results/:attemptId(\\d+)',
		name: 'elearning.attempts.review',
		meta: { requiresAuth: true, layout: 'none' },
		component: () => import('@/modules/elearning/pages/CourseAttemptReview.vue')
	},
	{
		path: '/elearning/courses/attempt/:certificateId(\\d+)',
		name: 'elearning.attempts.show',
		meta: { requiresAuth: true, layout: 'none' },
		component: () => import('@/modules/elearning/pages/CourseAttempt.vue')
	}
]

export default elearningRoutes
