const manualsRoutes = {
	path: '/manuals',
	name: 'modules.manuals',
	redirect: { name: 'manuals.dashboard' },
	meta: {
		requiresAuth: true,
		permissions: [ 'manuals' ]
	},
	component: () => import('@/modules/manuals/ManualsModule.vue'),
	children: [
		{
			name: 'permalinks',
			path: 'r',
			children: [
				{
					name: 'view-document',
					path: 'view-document/:documentId(\\d+)',
					redirect: to => {
						return {
							name: 'manuals.documents.show',
							params: { documentId: to.params.documentId }
						}
					},
				},
				{
					name: 'manage-document',
					path: 'manage-document/:documentId',
					redirect: to => {
						return {
							path: `/manuals/manage/documents/${to.params.documentId}`
						}
					},
				},
				{
					name: 'documentTimeline',
					path: ':manualId/:sectionId/:documentId/timeline',
					redirect: to => {
						return {
							name: 'manuals.admin.documents.show',
							params: {
								manualId: to.params.manualId,
								chapterId: 0,
								sectionId: to.params.sectionId,
								documentId: to.params.documentId
							},
							query: { tab: 'timeline' }
						}
					}
				},
			]
		},
		{
			path: 'manuals',
			name: 'manuals.manuals',
			redirect: { name: 'manuals.dashboard' },
			children: [
				{
					path: '',
					name: 'manuals.dashboard',
					component: () => import('@/modules/manuals/pages/ManualsDashboard.vue')
				},
				{
					path: ':manualId(\\d+)',
					name: 'manuals.manuals.show',
					component: () => import('@/modules/manuals/pages/Manual.vue')
				},
				{
					path: ':manualId(\\d+)/layer/:layerId(\\d+)/document/:documentId(\\d+)/versions/:versionId(\\d+)/files/:fileId(\\d+)',
					name: 'manuals.documents.versions.files.show',
					component: () => import('@/modules/manuals/pages/DocumentDownloadFile.vue'),
					meta: { layout: 'none' }
				},
				{
					path: 'chapters/:chapterId(\\d+)',
					name: 'manuals.chapters.show',
					component: () => import('@/modules/manuals/pages/Chapter.vue')
				},
				{
					// This is old URL for chapters.show.
					path: '/manuals/manuals/:manualId(\\d+)/chapters/:chapterId(\\d+)',
					redirect: to => {
						return {
							name: 'manuals.chapters.show',
							params: { chapterId: to.params.chapterId }
						}
					}
				},
				{
					path: 'sections/:sectionId(\\d+)',
					name: 'manuals.sections.show',
					component: () => import('@/modules/manuals/pages/Section.vue')
				},
				{
					// This is old URL for sections.show.
					path: '/manuals/manuals/:manualId(\\d+)/chapters/:chapterId(\\d+)/sections/:sectionId(\\d+)',
					redirect: to => {
						return {
							name: 'manuals.sections.show',
							params: { sectionId: to.params.sectionId }
						}
					}
				},
				{
					path: 'documents/:documentId(\\d+)',
					name: 'manuals.documents.show',
					component: () => import('@/modules/manuals/pages/ManualsDocument.vue')
				},
				{
					// This is old URL for documents.show.
					path: '/manuals/manuals/:manualId(\\d+)/layer/:layerId(\\d+)/document/:documentId(\\d+)',
					redirect: to => {
						return {
							name: 'manuals.documents.show',
							params: { documentId: to.params.documentId }
						}
					}
				},
				{
					path: 'documents/versions/:versionId(\\d+)',
					name: 'manuals.documents.versions.show',
					component: () => import('@/modules/manuals/pages/ManualsDocument.vue')
				},
				{
					// This is old URL for manuals.documents.versions.show.
					path: '/manuals/manuals/:manualId(\\d+)/layer/:layerId(\\d+)/document/:documentId(\\d+)/version/:versionId(\\d+)',
					redirect: to => {
						return {
							name: 'manuals.documents.versions.show',
							params: { versionId: to.params.versionId }
						}
					}
				},
			]
		},
		{
			path: ':status(expired|to_verify|declined)',
			name: 'manuals.status',
			component: () => import('@/modules/manuals/pages/Status.vue')
		},
		{
			path: 'manage',
			name: 'manuals.admin',
			redirect: { name: 'manuals.admin.index' },
			children: [
				{
					path: '',
					name: 'manuals.admin.index',
					component: () => import('@/modules/manuals/pages/Manage.vue')
				},
				{
					path: 'create',
					name: 'manuals.admin.manuals.create',
					component: () => import('@/modules/manuals/pages/ManualPage.vue'),
					props: { isNew: true, isEdit: true, }
				},
				{
					path: ':manualId(\\d+)/edit',
					name: 'manuals.admin.manuals.edit',
					component: () => import('@/modules/manuals/pages/ManualPage.vue'),
					props: { isNew: false, isEdit: true, }
				},
				{
					path: ':manualId(\\d+)',
					alias: 'manuals/:manualId(\\d+)',
					name: 'manuals.admin.manuals.show',
					component: () => import('@/modules/manuals/pages/ManualShow.vue')
				},
				{
					path: ':manualId(\\d+)/create',
					name: 'manuals.admin.chapters.create',
					component: () => import('@/modules/manuals/pages/ChapterPage.vue'),
					props: { isNew: true, isEdit: true, }
				},
				{
					path: ':manualId(\\d+)/:chapterId(\\d+)/edit',
					name: 'manuals.admin.chapters.edit',
					component: () => import('@/modules/manuals/pages/ChapterPage.vue'),
					props: { isNew: false, isEdit: true, }
				},
				{
					path: ':manualId(\\d+)/:chapterId(\\d+)',
					alias: '/manuals/manage/chapters/:chapterId(\\d+)',
					name: 'manuals.admin.chapters.show',
					component: () => import('@/modules/manuals/pages/AdminChapter.vue')
				},
				{
					path: ':manualId(\\d+)/:chapterId(\\d+)/create',
					name: 'manuals.admin.sections.create',
					component: () => import('@/modules/manuals/pages/SectionPage.vue'),
					props: { isNew: true, isEdit: true, }
				},
				{
					path: ':manualId(\\d+)/:chapterId(\\d+)/:sectionId(\\d+)/edit',
					name: 'manuals.admin.sections.edit',
					component: () => import('@/modules/manuals/pages/SectionPage.vue'),
					props: { isNew: false, isEdit: true, }
				},
				{
					path: ':manualId(\\d+)/:chapterId(\\d+)/:sectionId(\\d+)',
					alias: '/manuals/manage/sections/:sectionId(\\d+)',
					name: 'manuals.admin.sections.show',
					component: () => import('@/modules/manuals/pages/AdminSection.vue')
				},
				{
					path: ':manualId(\\d+)/:chapterId(\\d+)/:sectionId(\\d+)/create',
					name: 'manuals.admin.documents.create',
					component: () => import('@/modules/manuals/pages/AdminDocument.vue'),
					props: { isNew: true, isEdit: true, }
				},
				{
					path: ':manualId(\\d+)/:chapterId(\\d+)/:sectionId(\\d+)/:documentId(\\d+)/edit',
					name: 'manuals.admin.documents.edit',
					component: () => import('@/modules/manuals/pages/AdminDocument.vue'),
					props: { isNew: false, isEdit: true, }
				},
				{
					path: ':manualId(\\d+)/:chapterId(\\d+)/:sectionId(\\d+)/:documentId(\\d+)',
					alias: '/manuals/manage/documents/:documentId(\\d+)',
					name: 'manuals.admin.documents.show',
					component: () => import('@/modules/manuals/pages/AdminDocument.vue'),
					props: { isNew: false, isEdit: false, }
				}
			]
		},
	]
}
export default manualsRoutes
